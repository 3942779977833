const config = {
  name: 'Pricing Widget',
  defaultStyles: {
    lineHeight: '26px',
  },
  inputs: [
    {
      name: 'productPermalink',
      type: 'string',
      required: true,
      defaultValue: 'womens-cashmere-crew-honey',
    },
    {
      name: 'preText',
      type: 'string',
      helperText: 'Text that appears before the product price',
    },
    {
      name: 'postText',
      type: 'string',
      helperText: 'Text that appears after the product price',
    },
  ],
}

export default config
