/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// This component modifies the link behavior for existing links which should
// already have keyboard handlers and roles

import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { getPageFromUrl, isInternal } from 'lib/routing'

function findNearestLink(el) {
  let node = el
  while (node) {
    if (node.tagName === 'A') {
      return node
    }
    node = node.parentNode
  }

  return null
}

export default function ManualLinkRouter({ children }) {
  const router = useRouter()

  function clickHandler(event) {
    const link = findNearestLink(event.target)
    if (!link) return
    if (!isInternal({ href: link.href })) return
    event.preventDefault()
    const relativeUrl = link.href.replace(`${link.protocol}//${link.host}`, '')
    const page = getPageFromUrl(link.href, link.search)
    const isHash = !!link.href.split('#')[1]

    router.push(page, relativeUrl, { scroll: !isHash, shallow: isHash })
  }

  return <div onClick={clickHandler}>{children}</div>
}

ManualLinkRouter.propTypes = {
  children: PropTypes.any.isRequired,
}
