import getConfig from 'next/config'
import { useEffect, useState } from 'react'
import { BuilderComponent, BuilderContent } from '@builder.io/react'
import Skeleton from 'react-loading-skeleton'

import useFetchBuilder from 'lib/hooks/useFetchBuilder'
import ManualLinkRouter from 'components/ManualLinkRouter'
import checkForIsBuilderEditing from 'components/core/BuilderComponent/BuilderHelpers'

import './BuilderRegistry'
// https://www.builder.io/c/docs/widgets#lazy-loading-widgets
import '@builder.io/widgets/dist/lib/builder-widgets-async'
import '@everlane/builder'

const FONT_MAP = {
  'Graphik Medium': 'Maison Neue Demi',
  'Graphik Medium Italic': 'Maison Neue Book Italic',
  graphik: 'Maison Neue Book',
  'graphik--medium': 'Maison Neue Demi',
  'graphik--italic': 'Maison Neue Book Italic',
  'graphik--light': 'Maison Neue Light',
  'graphik--bold': 'Maison Neue Bold',
}

const replaceFonts = filter => {
  if (!filter) {
    return null
  }

  const escapeGraphik = Object.keys(FONT_MAP).join('|')
  const encodeRegex = new RegExp(escapeGraphik, 'g')

  return filter.replaceAll(encodeRegex, match => FONT_MAP[match])
}

const BuilderComponentWrapper = ({
  model,
  data,
  url,
  entry,
  userAttributes,
  contentLoaded = () => null,
}: {
  model: string
  data?: any
  url?: string
  entry?: string
  userAttributes?: any
  contentLoaded?: any
}) => {
  const [isBuilderEditing] = useState(checkForIsBuilderEditing())
  const [builderContent, setBuilderContent] = useState(null)
  const [everlaneBuilderModuleLoaded, setEverlaneBuilderModuleLoaded] = useState(false)

  const {
    publicRuntimeConfig: { USE_MAISON },
  } = getConfig()

  const { builderPage } = useFetchBuilder({
    model,
    userAttributes,
    url,
    data: { ...data, entry: isBuilderEditing ? null : entry },
  })

  useEffect(() => {
    async function loadEverlaneBuilderModule() {
      const { builder } = await import('@builder.io/react')
      builder.init(process.env.BUILDER_API_KEY)
      await import('./BuilderRegistry')
      setEverlaneBuilderModuleLoaded(true)
    }
    if (!everlaneBuilderModuleLoaded) loadEverlaneBuilderModule()
  })

  useEffect(() => {
    if (everlaneBuilderModuleLoaded && builderPage) {
      const { data: builderData } = builderPage
      const dataAsString = JSON.stringify(builderData)
      const replacedBuilderFonts = replaceFonts(dataAsString)
      const parsedData =
        USE_MAISON && replacedBuilderFonts ? JSON.parse(replacedBuilderFonts) : builderData

      setBuilderContent({ ...builderPage, data: { ...parsedData, customFonts: [] } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [everlaneBuilderModuleLoaded, builderPage])

  if (!everlaneBuilderModuleLoaded || (!builderContent && model !== 'homepage')) return null

  return (
    <div style={{ letterSpacing: USE_MAISON ? 'normal !important' : '' }}>
      <ManualLinkRouter>
        {model === 'homepage' && !builderContent ? (
          <Skeleton height="80vh" width="100vw" />
        ) : (
          <BuilderComponent
            content={builderContent as BuilderContent}
            contentLoaded={contentLoaded}
            model={model}
          />
        )}
      </ManualLinkRouter>
    </div>
  )
}

export default BuilderComponentWrapper
