const config = {
  name: 'Black Friday Ticker',
  inputs: [
    { name: 'isSticky', type: 'boolean', defaultValue: false },
    { name: 'tickerColor', type: 'color', defaultValue: 'rgba(255, 255, 255, 0.2)' },
    { name: 'overlayColor', type: 'color', defaultValue: '#fff' },
  ],
}

export default config
