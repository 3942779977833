import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Builder } from '@builder.io/react'

import notify from 'lib/errors'
import useSession from 'lib/network/hooks/useSession'
import useGeolocation from 'lib/network/hooks/useGeolocation'
import useIsMobile from 'lib/hooks/useIsMobile'

const useSetBuilderAttributes = ({
  skip,
  builderModule,
  url,
  userAttributes = {},
}: {
  skip?: boolean
  builderModule: Builder
  url: string
  userAttributes?: any
}) => {
  let isLoading = true
  const { data: session } = useSession()
  const { data: geolocation } = useGeolocation()
  const isMobile = useIsMobile()
  const { asPath } = useRouter()

  if (skip) return { isLoading }

  if (session && geolocation) {
    const { user, visitor } = session ?? {}
    const { gender: userGender = null } = user ?? {}
    const { countryCode, city } = geolocation ?? {}
    const { segment = null, gender: visitorGender = null } = visitor ?? {}

    let builderGender = userGender || visitorGender
    if (builderGender !== 'male') {
      builderGender = 'female'
    }

    // We can not currently use the builder targeting for the homepage so we're adding a hack here
    // If the gender pulled from the session is male or female we're going to route them to the correct HP
    // If asPath is not / we can assume this belongs to a landing page and we can use the urlPath
    // We need to strip off any query params or hash tags
    const [path] = asPath.split(/[?#]+/)
    let urlPath
    if (path === '/') {
      urlPath = builderGender === 'female' ? '/womens' : '/mens'
    } else {
      // We need to omit the query params from the path for Builder. Unfortunately the best way
      // to retrieve the the named path for _all_ pages is to remove search params from `asPath`
      urlPath = path
    }

    const isSignedIn = !!user
    const visitType = segment ?? 'unactivated'
    const defaultAttributes = {
      device: isMobile ? 'mobile' : 'desktop',
      city,
      countryCode,
      domesticCustomer: countryCode === 'US',
      visitType,
      gender: builderGender,
      isSignedIn,
      urlPath,
    }

    builderModule.setUserAttributes({ ...defaultAttributes, ...userAttributes })

    isLoading = false
  } else {
    // set default attributes so that users still see content
    // I'm not sure this is totally necessary.
    builderModule.setUserAttributes({
      device: isMobile ? 'mobile' : 'desktop',
      city: 'San Francisco',
      countryCode: 'US',
      domesticCustomer: true,
      visitType: 'unknown',
      gender: 'unknown',
      isSignedIn: false,
      urlPath: url || asPath,
    })
  }

  return { isLoading }
}

const useFetchBuilder = ({
  model,
  data = null,
  url = null,
  userAttributes,
}: {
  model: string
  data?: any
  url?: string
  userAttributes?: any
}) => {
  const [builderModule, setBuilderModule] = useState<Builder>(null)
  const [hasInitBuilder, setHasInitBuilder] = useState(false)
  const [builderPage, setBuilderPage] = useState(null)
  const [error, setError] = useState<string>()

  // Step 1: Initialize Builder
  useEffect(() => {
    async function initBuilder() {
      const { builder } = await import('@builder.io/react')
      builder.init(process.env.BUILDER_API_KEY)
      setBuilderModule(builder)
      setHasInitBuilder(true)
    }

    if (!hasInitBuilder) {
      initBuilder()
    }
  }, [hasInitBuilder])

  // Step 2: Set user attributes for builder targeting
  const { isLoading } = useSetBuilderAttributes({
    skip: !hasInitBuilder,
    builderModule,
    url,
    userAttributes,
  })

  // Step 3: Fetch the builder LP data
  useEffect(() => {
    const fetchBuilder = async () => {
      try {
        let builderData
        if (data?.entry) {
          // use builder.getAll
          const isMainEnvironment = process.env.BUILDER_MAIN_ENV === 'true'
          const query = {
            query: {
              // Builder prefixes each content ID with the apiKey for each environment, except [Main]
              id: isMainEnvironment ? data.entry : `${process.env.BUILDER_API_KEY}_${data.entry}`,
            },
          }
          builderData = await builderModule
            .getAll(model, { url, ...query })
            .then(response => response[0])
        } else {
          // use builder.get
          builderData = await builderModule.get(model, { url }).promise()
        }
        setError(builderData || isLoading ? null : 'No Component')
        setBuilderPage(builderData)
      } catch (err) {
        notify(err, {
          metadata: err,
          metadataName: 'fetchBuilderError',
        })
        setBuilderPage(null)
      }
    }

    if (hasInitBuilder && !isLoading) {
      fetchBuilder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitBuilder, isLoading, url, model])

  return { builderPage, isLoading, error }
}

export default useFetchBuilder
