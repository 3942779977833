const config = {
  name: 'Scroll To',
  inputs: [
    {
      name: 'scrollElement',
      type: 'reference',
      required: true,
    },
    {
      name: 'pathName',
      type: 'text',
      required: false,
      helperText: 'This is what will appear in the URL when the anchor is clicked.',
    },
  ],
}

export default config
