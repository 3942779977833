const config = {
  name: 'Product Overview Tile / Desktop',
  friendlyName: 'Product Overview Tile [Depricated]',
  defaultStyles: {
    padding: '16px',
    width: '333px',
    backgroundColor: '#ffffff',
  },
  inputs: [
    {
      name: 'productPermalink',
      type: 'string',
      required: true,
      defaultValue: 'womens-organic-cotton-box-cut-pocket-tee-white',
    },
    {
      name: 'showPrice',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'showColorName',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'showBadge',
      type: 'boolean',
      defaultValue: false,
    },
  ],
}

export default config
