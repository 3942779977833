import { useEffect, useState } from 'react'

export const hasTouch = (): boolean => {
  return (
    'ontouchstart' in global?.document.documentElement ||
    global?.navigator.maxTouchPoints > 0 ||
    (global?.navigator as any)?.msMaxTouchPoints > 0
  )
}

declare global {
  interface Window {
    matchMedia: (query: string) => MediaQueryList
  }
}

const useIsMobile = (): boolean => {
  const matchMedia = global.window?.matchMedia('(max-width: 768px)')
  const [isMobile, setIsMobile] = useState(matchMedia?.matches || false)

  const handleResize = e => {
    setIsMobile(e?.matches || false)
  }

  useEffect(() => {
    if (matchMedia?.addEventListener) matchMedia.addEventListener('change', handleResize)

    return () => {
      if (matchMedia?.removeEventListener) matchMedia.removeEventListener('change', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isMobile
}

export default useIsMobile
